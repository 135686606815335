<template>
  <div>
    <div class="viewOnlineExam" v-if="showExam">
      <v-row>
        <v-col cols="12">
          <!-- <div class="title">
            <h1>{{ ExamData.class_name }}</h1>
          </div> -->
          <div class="title">
            <h2 class="main-color">
              {{ ExamData.exam_name }}
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" md="11" class="info-list">
          <ul class="inline">
            <li>
              <strong> {{ $t("Date From") }}: </strong
              >{{ ExamData.open_date_from }}
            </li>
            <li>
              <strong> {{ $t("Date To") }}: </strong>{{ ExamData.open_date_to }}
            </li>
            <li>
              <strong> {{ $t("Duration") }}: </strong
              >{{ ExamData.duration_hour }} :
              {{ ExamData.duration_min }}
            </li>
            <li>
              <strong> {{ $t("Marks") }}:</strong> {{ ExamData.exam_mark }}
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" md="11" class="hr-div">
          <hr />
        </v-col>
      </v-row>
      <v-row class="row-count-down" style="" v-if="duration > 0">
        <p class="remaining" style="margin-left: 15%; margin-bottom: -2px">
          {{ $t("Remaining Time") }}
        </p>
        <circular-count-down-timer
          :initial-value="duration"
          :size="70"
          :stroke-width="2"
          :seconds-stroke-color="'#7297FF'"
          :minutes-stroke-color="'#7297FF'"
          :hours-stroke-color="'#7297FF'"
          :underneath-stroke-color="'lightgrey'"
          :seconds-fill-color="'#ffffff'"
          :minutes-fill-color="'#ffffff'"
          :hours-fill-color="'#ffffff'"
          :hour-label="'hours'"
          :minute-label="'minutes'"
          :second-label="'seconds'"
          :show-second="true"
          :show-minute="true"
          :show-hour="true"
          :show-negatives="true"
          :notify-every="'minute'"
          @finish="submitWithoutValidation"
        ></circular-count-down-timer>
      </v-row>
      <v-form>
        <v-row>
          <v-col
            cols="11"
            sm="11"
            class="sectionBody"
            v-for="(section, index) in ExamData.sections"
            :key="index"
          >
            <v-card>
              <v-row>
                <v-col cols="12" sm="12">
                  <p class="section-title main-color" style="float: left">
                    {{ section.section_title }}
                  </p>
                  <p style="float: right" class="section-title main-color">
                    {{ section.section_total_marks }} {{ $t("Marks") }}
                  </p>
                </v-col>
              </v-row>

              <v-row
                v-for="(question, qindex) in section['questions']"
                :key="qindex"
                :class="
                  submited && question.answer == '' ? 'alert-danger ' : ''
                "
                style="margin-bottom: 20px"
              >
                <v-col cols="12" sm="12">
                  <v-row>
                    <v-col cols="9" md="9" sm="9">
                      <p class="text-left question">
                        <strong
                          class="answer-img-container"
                          v-html="question.question"
                        >
                        </strong>
                      </p>
                    </v-col>
                    <v-col cols="3" md="3">
                      <p>
                        <v-checkbox
                          v-model="remind_me[qindex]"
                          label="Remind Me"
                          value="true"
                        ></v-checkbox>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12">
                  <!-- essay -->
                  <v-textarea
                    v-if="question.type == 'essay'"
                    v-model="question.answer"
                    solo
                  ></v-textarea>
                  <!-- msq -->
                  <v-radio-group
                    v-model="question.answer"
                    v-if="question.type == 'tof' || question.type == 'mcq'"
                    row
                  >
                    <v-radio
                      v-for="(option, Oindex) in question.options"
                      :key="Oindex"
                      label=""
                      :value="option.value"
                      style="width: 100%; display: block"
                    >
                      <template slot="label">
                        <p
                          class="answer-img-container"
                          v-html="option.value"
                        ></p>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  <div v-if="question.type == 'join'">
                    <v-row
                      v-for="(join, Jindex) in question.joins"
                      :key="Jindex"
                    >
                      <v-col cols="4">
                        <p
                          :hidden="
                            join.join_question == '' ||
                              join.join_question == null
                          "
                        >
                          {{ join.join_question_number }} .
                          {{ join.join_question }}
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <div
                          :hidden="
                            join.join_question == '' ||
                              join.join_question == null
                          "
                        >
                          <v-select
                            :items="join.all_answers_items"
                            solo
                            v-model="join.answer"
                            @change="FilterCorrectAnswer(qindex, index, Jindex)"
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <p>
                          {{ join.join_answer_number }} . {{ join.join_answer }}
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- <hr /> -->
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="11" sm="11">
            <div class="btns text-right">
              <button
                class="ui-btn submit"
                :loading="loading"
                :disabled="loading"
                @click.prevent="submitData"
              >
                <!-- @click.prevent="" -->
                {{ $t("Submit Answers") }}
              </button>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div class="viewOnlineExam" v-if="!showExam">
      <v-row>
        <v-col cols="8" md="8">
          <v-alert class="info_alert">
            {{ showExamErrorText }}
          </v-alert>
        </v-col>
      </v-row>
    </div>

    <!-- Pop Up Remaind -->
    <v-dialog
      v-model="dialogRemind"
      persistent
      max-width="500"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{ $t("Remind") }}</v-card-title>
        <hr />

        <v-card-text>
          <p
            class=""
            style="
              display: block;
              margin-bottom: 15px !important;
              color: rgb(65, 66, 64);
              font-size: 17px;
            "
            v-if="questionsEmpty.length > 0"
          >
            Questions Number (<span
              style="color: #7297ff"
              v-for="question in questionsEmpty"
              :key="question"
            >
              {{ question + 1 }},</span
            >) <span style="color: red">{{ $t("Is Empty") }}</span>
          </p>
          <p
            class=""
            style="
              display: block;
              margin-bottom: 15px !important;
              color: rgb(65, 66, 64);
              font-size: 17px;
            "
            v-if="remindQuestion.length > 0"
          >
            {{ $t("Questions Number") }} (<span
              style="color: #7297ff"
              v-for="questionmark in remindQuestion"
              :key="questionmark"
            >
              {{ questionmark + 1 }},</span
            >) <span style="color: #7297ff">{{ $t("Is Mark Of Review") }}</span>
          </p>
        </v-card-text>
        <hr />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="submitWithoutValidation"
            :loading="loading"
            :disabled="loading"
            id="noBtn"
            ref="always_focus"
            >{{ $t("Thanks. Send The Answers Anyway") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="dialogRemind = false"
            class="modal-btn-save yesBtn"
            >{{ $t("Ok I Will Review") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "applicantViewOnlineExam",
  data() {
    return {
      ExamData: [],
      examId: "",
      duration: 0,
      showExam: 0,
      showExamErrorText: "",
      time_spent: 0,
      submited: false,
      loading: false,
      remind_me: [],
      questionsEmpty: [],
      remindQuestion: [],
      dialogRemind: false,
    };
  },
  methods: {
    FilterCorrectAnswer(questionIndex, sectionIndex, joinIndex) {
      let answer = this.ExamData.sections[sectionIndex]["questions"][
        questionIndex
      ]["joins"][joinIndex]["answer"];
      let empty_all_item_answers;
      empty_all_item_answers = [
        ...this.ExamData.sections[sectionIndex]["questions"][questionIndex][
          "joins"
        ][joinIndex]["all_answers_items"],
      ];
      let correct_answers_array = [];
      let question_numbers_array = [];
      this.ExamData.sections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        correct_answers_array.push(join.answer);
        question_numbers_array.push(join.join_answer_number);

        if (join.answer != answer && answer != "") {
          //   console.log(join);
          let all_answers_items = [];
          for (let index = 0; index < join.all_answers_items.length; index++) {
            if (join.all_answers_items[index] != answer) {
              all_answers_items.push(join.all_answers_items[index]);
            }
          }

          join.all_answers_items = [];
          join.all_answers_items = [...all_answers_items];
        } else if (answer == "") {
          empty_all_item_answers.forEach((item) => {
            let answer_found = join.all_answers_items.includes(item);
            console.log(answer_found);
            if (!answer_found) {
              join.all_answers_items.push(item);
            }
          });
        }
      });
      let questions_numbers_not_in_correct_answers = [];
      question_numbers_array.forEach((number) => {
        if (!correct_answers_array.includes(number)) {
          questions_numbers_not_in_correct_answers.push(number);
        }
      });

      this.ExamData.sections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        questions_numbers_not_in_correct_answers.forEach((number) => {
          if (!join.all_answers_items.includes(number)) {
            join.all_answers_items.push(number);
          }
        });
      });
    },
    submitData() {
      let canSubmit = true;
      // this.dialogRemind = true;

      this.ExamData.sections.forEach((section) => {
        section["questions"].forEach((question, index) => {
          if (question.answer == "") {
            canSubmit = false;
            this.questionsEmpty.push(index);
          }
        });
      });
      this.remind_me.forEach((element, index) => {
        if (element == "true" || element == true) {
          canSubmit = false;
          this.remindQuestion.push(index);
        }
      });

      // console.log(this.questionsEmpty);

      if (this.submited == false && !canSubmit) {
        this.submited = true;
        if (this.questionsEmpty.length > 0 || this.remindQuestion.length > 0) {
          this.dialogRemind = true;
        }
      } else {
        this.loading = true;

        axios
          .post(
            this.getApiUrl +
              "/onlineexams/admission/" +
              this.examId +
              "/" +
              this.$router.currentRoute.params.applicant_code,
            this.ExamData
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.loading = false;
              this.$router.push(
                {
                  path: "/finishExam",
                },
                () => {}
              );
            }
          });
      }
    },
    submitWithoutValidation() {
      this.loading = true;
      // console.log("submit done" + " canSubmit =>" + canSubmit);
      axios
        .post(
          this.getApiUrl +
            "/onlineexams/admission/" +
            this.examId +
            "/" +
            this.$router.currentRoute.params.applicant_code,
          this.ExamData
        )
        .then((response) => {
          // console.log(response);
          if (response.data.status.error == false) {
            this.dialogRemind = false;
            this.$router.push(
              {
                path: "/finishExam",
              },
              () => {}
            );
          }
        });
    },

    getExamData() {
      axios
        .get(
          this.getApiUrl +
            "/onlineexams/applicanttExam/" +
            this.examId +
            "/" +
            this.$router.currentRoute.params.applicant_code
        )
        .then((response) => {
          if (response.data.status.error) {
            this.showExam = false;
            this.showExamErrorText = response.data.status.message;
          } else {
            this.showExam = true;
            this.ExamData = response.data.data;
            this.duration = this.ExamData.duration_second;
            var date = new Date();

            this.ExamData.time_spent = date;
            console.log(date);
            console.log(this.ExamData.time_spent);
          }
        });
    },
  },
  mounted() {
    this.examId = this.$router.currentRoute.params.examId;
    this.getExamData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_forms.scss";

.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 20%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.exam-section {
  margin-bottom: 1rem;
}

.sectionInput {
  background-color: #eff3ff;
  padding: 25px 0px 25px 50px;
}
hr.hr-bold {
  border-top: 2px solid rgb(18 2 2 / 10%);
}

label {
  margin-top: 0 !important;
}
.clickable {
  cursor: pointer;
}
.questionbody {
  box-shadow: $box-shadow;
  padding-bottom: 2px !important;
  // margin-top: 2px;
  margin-bottom: 2px;
}
.addQuestion {
  padding: 10px;
  background-color: #eff3ff;
}

.addOption {
  padding: 5px;
  background-color: #eff3ff;
}
.divider {
  height: 2rem;
  width: 100%;
  background-color: #eff3ff;
  margin: 0 !important;
  padding: 0 !important;
}

.displayDiv {
  display: block !important;
}
.hiddenDiv {
  display: none;
}
.loader img {
  margin-top: 50px;
  display: inline-block;
  margin-right: 2rem;
}
.answer-img-container {
  // margin: 20px 0 !important;
}
.block {
  display: block !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
</style>
